/* eslint-disable */ 
import edit2PNG from '@/assets/images/edit_30.png';
import close2PNG from '@/assets/images/delete_30.png';
import scalePNG from '@/assets/images/scale_30.png';
import textClonePNG from '@/assets/images/clone_30.png';
import DemoJPG from '@/assets/images/demo.jpg';
import Demo2JPG from '@/assets/images/demo2.jpg';

const eidtIconImg = document.createElement('img');
eidtIconImg.src = edit2PNG;//editIcon64; 

const scaleIconImg = document.createElement('img');
scaleIconImg.src = scalePNG;

const textCloseIconImg = document.createElement('img');
textCloseIconImg.src = close2PNG; //textCloseIcon64;

const textCloneIconImg = document.createElement('img');
textCloneIconImg.src = textClonePNG;

const ICON_SIZE = 25;



function renderImageScaleIcon(ctx, left, top, styleOverride, fabricObject) {
	let w = ICON_SIZE, h = ICON_SIZE;
	ctx.save();
	ctx.translate(left, top);
	ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
	ctx.drawImage(scaleIconImg, -w/2, -h/2, w, h);
	ctx.restore();
}
function renderImageScaleXIcon(ctx, left, top, styleOverride, fabricObject) {
	let w = ICON_SIZE, h = ICON_SIZE;
	ctx.save();
	ctx.translate(left, top);
	ctx.rotate(fabric.util.degreesToRadians(-45));
	ctx.drawImage(scaleIconImg, -w/2, -h/2, w, h);
	ctx.restore();
}

function renderTextEditIcon(ctx, left, top, styleOverride, fabricObject) {
	let w = ICON_SIZE, h = ICON_SIZE;
	ctx.save();
	ctx.translate(left, top);
	ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
	ctx.drawImage(eidtIconImg, -w/2, -h/2, w, h);
	ctx.restore();
}

function renderTextCloseIcon(ctx, left, top, styleOverride, fabricObject) {
	let w = ICON_SIZE, h = ICON_SIZE;
	ctx.save();
	ctx.translate(left, top);
	ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
	ctx.drawImage(textCloseIconImg, -w/2, -h/2, w, h);
	ctx.restore();
}

function renderTextCloneIcon(ctx, left, top, styleOverride, fabricObject) {
	let w = ICON_SIZE, h = ICON_SIZE;
	ctx.save();
	ctx.translate(left, top);
	ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
	ctx.drawImage(textCloneIconImg, -w/2, -h/2, w, h);
	ctx.restore();
}

function cloneObject(eventData, transform) {
	var target = transform.target;
	var canvas = target.canvas;
	target.clone(function(cloned) {
		cloned.left += 10;
		cloned.top += 10;
		cloned.padding = 10
		cloned.on('selected', _this.selectedHandle)
		canvas.add(cloned)
	});
}

let controlsUtils = fabric.controlsUtils,
	scaleSkewStyleHandler = controlsUtils.scaleSkewCursorStyleHandler,
	scaleStyleHandler = controlsUtils.scaleCursorStyleHandler,
	scalingEqually = controlsUtils.scalingEqually,
	scalingYOrSkewingX = controlsUtils.scalingYOrSkewingX,
	scalingXOrSkewingY = controlsUtils.scalingXOrSkewingY,
	scaleOrSkewActionName = controlsUtils.scaleOrSkewActionName,
	objectControls = fabric.Object.prototype.controls;

const editControl = (handle)=> 
	new fabric.Control({
		x: 0.5,
		y: -0.5,
		cursorStyle: 'pointer',
		mouseUpHandler: handle,
		render: renderTextEditIcon,
		cornerSize: 32,
	});


const scaleControl = (handle)=> new fabric.Control({				
		x: 0.5,
		y: 0.5,
		cursorStyle: 'pointer',
		render: renderImageScaleIcon,
		cornerSize: 32,
		actionHandler:scalingEqually,
		visible: true,
	});

const cloneControl = (handle)=> new fabric.Control({				
		x: -0.5,
		y: -0.5,
		cursorStyle: 'pointer',
		render: renderTextCloneIcon,
		cornerSize: 32,
		mouseUpHandler: handle,
	});

const textCloseControl = (handle)=> new fabric.Control({				
		x: -0.5,
		y: 0.5,
		cursorStyle: 'pointer',
		render: renderTextCloseIcon,
		cornerSize: 32,
		mouseUpHandler: handle,
	});

const scaleXControl = (handle)=> new fabric.Control({				
	x: 0.5,
	y: -0.5,
	cursorStyle: 'pointer',
	render: renderImageScaleXIcon,
	cornerSize: 32,
	actionHandler:scalingXOrSkewingY,
	visible: true,
});


const TextControls = function({
	editHandle,  
	scaleHandle,
	closeHandle,
	cloneHandle
}) {
	return {
		edit: editControl(editHandle), 
		scale: scaleControl(scaleHandle), 
		text_close: textCloseControl(closeHandle),  
		clone: cloneControl(cloneHandle)
	}
}

const ImageControls = function({
	scaleXHandle,  
	scaleXYHandle,
	closeHandle,
	cloneHandle
}) {
	return {
		scaleX: scaleXControl(scaleXHandle), 
		scaleXY: scaleControl(scaleXYHandle), 
		text_close: textCloseControl(closeHandle),  
		clone: cloneControl(cloneHandle)
	}
}
const TEXT_MODEL = {
	type: 'text',
	text: '',
	css: {
		border: {
			width: {
				x: 0,
				y: 0,
			}, 
			color: '', 
			radius: {
				x: 0,
				y: 0
			}
		},
		text: {
			color: '#333',
			fontSize: 18,
			underline: false,
			linethrough: false,
			fontStyle: 'normal',
			fontWeight: 200,
			fontFamily: 'Times New Roman',
			charSpacing: 0
		},
		top: 0,
		left: 0,
	},
	originX: 'center',
	originY: 'center',
}

const IMAGE_MODEL = {
	type: 'image',
	url: '',
	base64: '',
	css: {
		border: {
			width: 0,
			radius: 0,
			color: '',
		},
		top: 0,
		left: 0,
		width: 200,
		height: undefined,
	},
	originX: 'center',
	originY: 'center',
}

const DEFAULT_SELECTION_PADDING = 5

export {
	TextControls,
	ImageControls,
	DemoJPG,
	Demo2JPG,
	TEXT_MODEL,
	IMAGE_MODEL,
	DEFAULT_SELECTION_PADDING,
}