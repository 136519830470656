//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/* eslint-disable */
import {fabric} from 'fabric';
import QRCode from 'qrcode';
import wx from 'weixin-js-sdk';
import axios from 'axios';
import LOGGER from '@/apis/log';
import {TextControls, DemoJPG, ImageControls, Demo2JPG, TEXT_MODEL, IMAGE_MODEL, DEFAULT_SELECTION_PADDING} from '@/utils/fabric';

const rate = window.screen.height / window.screen.width;    
let limit =  window.screen.height == window.screen.availHeight ? 1.8 : 1.65;
const FULL_SCREEN = rate > limit

const SAFE_AREA_DIFF = FULL_SCREEN ? 64 : 44;
const AVAILD_WIDTH = window.screen.availWidth;
const AVAILD_HEIGHT = window.screen.availHeight - 88;  //真机下availdheight会包括顶部状态栏和导航栏高度
const SCALE_RATIO = AVAILD_WIDTH > 375 && AVAILD_HEIGHT > (750 - 88) ? 1: 0.75;  //老的plus型号，宽度够但是高度不够
const POSTER_WIDTH = 360 * SCALE_RATIO;
const POSTER_HEIGHT = 640 * SCALE_RATIO;
const MARGIN_LEFT = AVAILD_WIDTH / 2 - POSTER_WIDTH / 2;
const MARGIN_TOP = AVAILD_HEIGHT / 2 - POSTER_HEIGHT / 2 - SAFE_AREA_DIFF / 2;
const CANVAS_WIDTH = AVAILD_WIDTH;
const CANVAS_HEIGHT = !FULL_SCREEN ? (AVAILD_HEIGHT): (AVAILD_HEIGHT - 20);

const ICON_SIZE = 20;
const DEFAULT_IMAGE_WIDTH = 200;



//修复真机上canvas不能滑动的BUG https://github.com/fabricjs/fabric.js/pull/5904
(function() {
	var addListener = fabric.util.addListener,
			removeListener = fabric.util.removeListener,
			addEventOptions = { passive: false };

	fabric.util.object.extend(fabric.Canvas.prototype, /** @lends fabric.Canvas.prototype */ {
	_onTouchStart: function(e) {
		var targetR = this.findTarget(e);
		!this.allowTouchScrolling && e.preventDefault && e.preventDefault();
		targetR && e.preventDefault && e.preventDefault();
		if (this.mainTouchId === null) {
			this.mainTouchId = this.getPointerId(e);
		}
		this.__onMouseDown(e);
		this._resetTransformEventData();
		var canvasElement = this.upperCanvasEl,
		eventTypePrefix = this._getEventPrefix();
		addListener(fabric.document, 'touchend', this._onTouchEnd, addEventOptions);
		addListener(fabric.document, 'touchmove', this._onMouseMove, addEventOptions);
		// Unbind mousedown to prevent double triggers from touch devices
		removeListener(canvasElement, eventTypePrefix + 'down', this._onMouseDown);
	}
	});
})();

import FontFaceObserver from 'fontfaceobserver';
export default {
	metaInfo () {
      	return {
			title: '海报制作',
		}
	},
	data: ()=> ({
		canvas: null,
		zeroPoint: {
			top: 0,
			left: 0,
		},
		localImgUrl: null,
		width: 0,
		height: 0,
		showTextEditModel: false,
		textEditModelContent: null,
		showImgMenu: false,
		showTextMenu: false,
		showColorMenu: false,
		id: null,
		bgObj: null,
		fontList: [
			{ name: '默认', color: '#ee0a24', chosen: true },
        	// { name: '禁用选项', disabled: true },
			{ name: '优设标题黑', loading: true,},
        	{ name: '夏行楷', loading: true,},
			{ name: '联盟起艺卢帅正锐黑体', loading: true},
			{ name: 'Klipan', loading: true},
			{ name: 'Facon', loading: true},

		],
		showFontChooseSheet: false,
		imageGroupBorder: {
			width: 0,
			radius: 0,
		},
		config: {
			canvasState: [],
			currentStateIndex: -1,
			undoStatus: false,
			redoStatus: false,
			disableUndo: true,
			disableRedo: true,
			enable: false,
		},
		loading: false,
	}),
	props: {
		array: Array,
		appId: String,
		posterKey: String,
	},
	created: async function() {
		this.id = new Date().getTime()
		this.height = CANVAS_HEIGHT,
		this.width = CANVAS_WIDTH
	},
	mounted: async function() {
		this.initCanvas()
		await this.initBg()	
		await this.loadFonts()
		this.initWxConfig()	
		this.config.undoButton = this.$refs.undo
		this.config.redoButton = this.$refs.redo
		this.$watch("array", async(val,oldval)=>{
			await this.initPosterObjects(val)
			this.initCanvasState()
		})
	},
	methods: {
		//2021-4-8
		async initPosterObjects(objects) {
			console.log(objects)
			for(let obj of objects) {
				let {type} = obj
				if(type == 'image') {
					await this.addImageGroupHandle(obj)
				}
				if(type == 'text') {
					await this.addTextGroupHandle(obj)
				}
				if(type == 'qrcode') {
					await this.addQrcodeGroupHandle(obj)
				}
			}
		},


		//2021-4-7
		initCanvas() {
			this.canvas = new fabric.Canvas('canvas', {allowTouchScrolling: true}) //必须在mounted之后创建
			this.canvas.backgroundColor = '#fff'
			fabric.Object.prototype.objectCaching = false
			this.canvas.preserveObjectStacking = true  //https://github.com/fabricjs/fabric.js/issues/6696 低层对象被选中时不会在顶部显示
			this.canvas.controlsAboveOverlay = true  //https://github.com/Rookie-Birds/Fabric-Tutorial_zh-CN/blob/master/part-8.md
			let clipPath2 = new fabric.Rect({
				top: MARGIN_TOP,
				left: MARGIN_LEFT,
				width: POSTER_WIDTH, //40 * 9  => 30 * 9
				height: POSTER_HEIGHT,  //40 * 16 => 30 * 16
			});
			this.canvas.clipPath = clipPath2
			this.zeroPoint = {
				left: MARGIN_LEFT,
				top: MARGIN_TOP,
			}
			this.canvas.on('selection:cleared', this.clearSelection)
			this.canvas.on('selection:updated', this.groupSelectHandle)
			this.canvas.on('selection:created', this.groupSelectHandle)
		},
		initBg() {
			return new Promise((resolve, reject)=> {
				var rect = new fabric.Rect({
					left: MARGIN_LEFT,
					top: MARGIN_TOP,
					fill: 'white',
					width: POSTER_WIDTH,
					height: POSTER_HEIGHT
				});
				this.canvas.add(rect)
				rect.sendToBack();
				rect.set('selectable', false);
				resolve()
			})
		},

		loadFonts: function(fm) {
			console.log(fm)
			if(fm == 'PingFang SC' || fm == 'Times New Roman') {
				fm = '默认'
			}
			this.fontList.forEach(item => {
				let {name, loading} = item
				if(loading) {
					let font = new FontFaceObserver(name, {
						weight: 400
					});
					font.load().then(function () {
						console.log('Font is available, ' + name);
						item.loading = false
					}, function (e) {
						console.log('Font is not available, ' + name);
					});
				}
				item.chosen = fm == item.name
			})	
		},

		async addQrcodeGroupHandle(obj){
			let qrcode = await QRCode.toDataURL(obj.content, {color: {dark: obj.css.color, light: obj.css.light},margin:3})
			obj.url = qrcode
			let imageGroup = await this._imageGroupHandle(obj)
			imageGroup.on('selected', this.groupSelectHandle)
			this.canvas.add(imageGroup)
		},
		async addImageGroupHandle(obj) {
			//从相册或者相机选择照片  返回base64
			console.log(obj)
			if(!obj || obj.type == 'click') {
				let localImageData = this.weAppEnv ? await this._getLocalImage() : DemoJPG
				console.log('add new Image', localImageData.length)
				obj = {
					url: localImageData,
					css: {
						width: 200,
						top: 200,
						left: 200,
						border: {
							width: 0,
							color: '',
							radius: 0,
						}
					}
				}
			}
			let imageGroup = await this._imageGroupHandle(obj)
			imageGroup.on('selected', this.groupSelectHandle)
			this.canvas.add(imageGroup)
		},

		async _imageGroupHandle(obj) {
			let imageObj = await this._imageLoader(obj)
			let {border:{width = 0, color = '', radius = 0}} = obj.css
			let imgClip = new fabric.Rect({
				width: imageObj.width,
				height: imageObj.height,
				originX: 'center',
				originY: 'center',
				rx: radius / imageObj.scaleX,
				ry: radius / imageObj.scaleY,
			}) 
			imgClip.set('selected', this.clipPathSelectHandle)
			imageObj.clipPath = imgClip
			imageObj.ctype = 'image'
			let bgRect = new fabric.Rect({
				width: imageObj.width * imageObj.scaleX + width * 2,
				height: imageObj.height * imageObj.scaleY + width * 2,
				fill: color,
				originX: 'center',
				originY: 'center',
				rx: radius + width,
				ry: radius + width,
			})
			bgRect.ctype = 'bg'
			let group = new fabric.Group([bgRect, imageObj], {
				left: obj.css.left * SCALE_RATIO + this.zeroPoint.left,
				top: obj.css.top * SCALE_RATIO + this.zeroPoint.top,
				originX: 'center',
				originY: 'center',
				padding: DEFAULT_SELECTION_PADDING,
				scaleX: SCALE_RATIO,
				scaleY: SCALE_RATIO,
			})
			group.controls = this._buildImageGroupControls(group)
			group.originObj = obj
			group.ctype = 'image-group'
			return group
		},
		_imageLoader(obj) {
			return new Promise((resolve)=> {
				if(obj.url.startsWith('http')) {
					new fabric.Image.fromURL(obj.url, function(img) {
						let scaleRatio = obj.css.width ? obj.css.width / img.width  : 1
						img.scale(scaleRatio); 
						img.originX = 'center'
						img.originY = 'center'
						resolve(img)
					}, {crossOrigin: 'anonymous'});
				} else {
					const tmp = document.createElement('img');
					tmp.src = obj.url || obj.base64;
					tmp.onload = ()=> {
						let {width, height} = obj.css
						let defaultWidth = DEFAULT_IMAGE_WIDTH
						if(!width && !height) { //宽高都未设置
							width = defaultWidth
							height = tmp.height / (tmp.width / defaultWidth)
						} else if(!width && height) {
							width = tmp.width / (tmp.height / height)
						} else if(width && !height) {
							height = tmp.height / (tmp.width / defaultWidth)
						}
						var imgInstance = new fabric.Image(tmp, {
							width: tmp.width,
							height: tmp.height,
							originX: 'center',
							originY: 'center'
						});
						imgInstance.scale(width / tmp.width)
						imgInstance.originObj = obj
						resolve(imgInstance)
					}
				}
			})
		},

		_buildImageGroupControls(group) {
			let _this = this
			return ImageControls({
				scaleXHandle: (e)=> {
					console.log(e)
				},
				scaleXYHandle: (e)=> {
					console.log(e)
				},
				closeHandle: (e)=> {
					console.log(e)
					_this.canvas.remove(group)
					_this.clearSelection()
					_this.updateCanvasState()
				},
				cloneHandle: async (eventData, transform)=> {
					var target = transform.target;
					var canvas = target.canvas;
					target.clone(function(cloned) {
						cloned.left += 10;
						cloned.top += 10;
						cloned.padding = 5
						cloned.controls = _this._buildImageGroupControls(cloned)
						cloned.originObj = Object.assign({}, target.originObj)
						console.log(cloned)
						canvas.add(cloned)
					}, ['ctype', 'originObj']);
				}
			})
		},

		async updateImageHandle() {
			let newImage = await this._getLocalImage()
			console.log('change Image', newImage.length)
			let group = this.canvas.getActiveObject()
			let imageObjs = group['_objects'].filter(item => item.ctype == 'image')
			let bgObjs = group['_objects'].filter(item => item.ctype == 'bg')
			let imageObj = imageObjs ? imageObjs[0] : null
			let bgObj = bgObjs ? bgObjs[0] : null
			let obj = group.originObj
			if(imageObj) {
				//替换前后保持宽一样
				let {width, scaleX} = imageObj
				let imgW = width * scaleX
				imageObj.setSrc(newImage, ()=> {
					let s = imgW / imageObj.width
					imageObj.set({
						width: imageObj.width,
						scaleX: s,
						scaleY: s,
						height: imageObj.height,
						dirty: true
					})
					let {clipPath} = imageObj
					clipPath.set({
						width: imageObj.width,
						height: imageObj.height,
						dirty: true
					})
					let {border:{width: borderWidth}} = obj.css
					bgObj.set({
						width: imageObj.width * imageObj.scaleX + borderWidth * 2,
						height: imageObj.height * imageObj.scaleY + borderWidth * 2,
						dirty: true
					})
					this.canvas.requestRenderAll()
					this.canvas._updateActiveSelection()
				})
			}
			this.updateCanvasState()
		},

		clipImageHandle() {
			let group = this.canvas.getActiveObject()
			let imageObj = group['_objects'].filter(item => item.ctype == 'image')
			let clipObj = imageObj ? imageObj[0].clipPath : null
			if(clipObj) {
				debugger
				this.canvas.discardActiveObject()
				this.canvas.renderAll()
				// this.canvas.setActiveObject(clipObj)
			}
		},
		clipPathSelectHandle() {
			console.log('clip selected')
		},

		addTextGroupHandle(obj) {
			let textGroup = this._textGroupHandle(obj)
			textGroup.on('selected', this.groupSelectHandle)
			this.canvas.add(textGroup)
		},

		_textGroupHandle(obj) {
			let {
				css: {
					border: {
						width: {
							x: borderWidthX = 0,
							y: borderWidthY = 0,
						}, 
						color: borderColor = '', 
						radius: {
							x: radiusX = 0,
							y: radiusY = 0
						}
					},
					text: {
						color = '#333',
						fontSize = 18,
						underline,
						linethrough,
						fontWeight,
						fontStyle,
						fontFamily,
						shadow,
						charSpacing = 1,
					},
					top = 0,
					left = 0,
				},
				originX = 'center',
				originY = 'center',
			} = obj
			let text = new fabric.Text(obj.text, {
				fill: color,
				fontSize: fontSize,
				originX: 'center',
				originY: 'center',
				textAlign: 'justify-center',
				underline,
				linethrough,
				fontWeight,
				fontStyle,
				fontFamily,
				shadow,
				charSpacing: charSpacing || 1,
			})
			text.ctype = 'text'
			let bgRect = new fabric.Rect({
				width: text.width + borderWidthX * 2,
				height: text.height + borderWidthY * 2,
				fill: borderColor,
				rx: radiusX, 
				ry: radiusY,
				originX: 'center',
				originY: 'center'
			})
			bgRect.ctype = 'bg'
			let group = new fabric.Group([bgRect, text], {
				top: top * SCALE_RATIO + this.zeroPoint.top,
				left: (originX == 'right' ? (POSTER_WIDTH - left * SCALE_RATIO) : (left * SCALE_RATIO) ) + this.zeroPoint.left,
				cornerStyle:'circle',
				originX: originX,
				originY: originY,
				padding: DEFAULT_SELECTION_PADDING,
				scaleX: SCALE_RATIO,
				scaleY: SCALE_RATIO,
			})
			group.originObj = obj
			group.ctype = 'text-group'
			group.controls = this._buildTextGroupControls(group)
			return group
		},

		_buildTextGroupControls(group) {
			let _this = this
			let textObj = group['_objects'].filter(item => item.ctype == 'text')[0]
			return TextControls({
				editHandle: (e)=> {
					console.log(e)
					this.showTextEditModel = true
					this.textEditModelContent = textObj.text.replace(/\n/g,"");
				},
				scaleHandle: (e)=> {
					console.log(e)
				},
				closeHandle: (e)=> {
					console.log(e)
					_this.canvas.remove(group)
					_this.clearSelection()
					this.updateCanvasState()
				},
				cloneHandle: async (eventData, transform)=> {
					var target = transform.target;
					var canvas = target.canvas;
					target.clone(function(cloned) {
						cloned.left += 10;
						cloned.top += 10;
						cloned.padding = 5
						cloned.controls = _this._buildTextGroupControls(cloned)
						cloned.originObj = Object.assign({}, target.originObj)
						console.log(cloned)
						canvas.add(cloned)
					}, ['ctype', 'originObj']);
				}
			})
		},

		groupSelectHandle() {
			let cnt = this.canvas.getActiveObjects().length
			console.log(cnt)
			if(cnt != 1) {
				//0表示点击完成，1表示选中某个图或者文本
				this.canvas.discardActiveObject()
				this.canvas.requestRenderAll()
				return
			}
			
			let obj = this.canvas.getActiveObject()
			let ctype = obj.ctype
			let hasCloseCtl = obj.controls.close || obj.controls.text_close
			if(!ctype) {
				this.canvas.discardActiveObject()
				this.canvas.requestRenderAll()
				return
			}	
			this.showTextMenu = ctype == 'text-group'
			this.showImgMenu = ctype == 'image-group'
			if(!hasCloseCtl) {
				obj.padding = DEFAULT_SELECTION_PADDING
				if(ctype == 'text-group') {
					obj.controls = this._buildTextGroupControls(obj)
				}
				if(ctype == 'image-group') {
					obj.controls = this._buildImageGroupControls(obj)
				}
			}
		},
		clearSelection() {
			this.canvas.discardActiveObject()
			this.canvas.requestRenderAll()
			this.showTextMenu = false
			this.showTextEditModel = false
			this.textEditModelContent = ''
			this.showImgMenu = false
		},

		addTextHandle:function () {
			let obj = Object.assign({}, TEXT_MODEL, 
				{
					text: '点我修改文字',
				})
			obj.css.left = CANVAS_WIDTH / 2
			obj.css.top = CANVAS_HEIGHT / 2
			this.addTextGroupHandle(obj)
		},

		textEditModalCancleHandle: function() {
			this.showTextEditModel = false
			this.textEditModelContent = null
		},

		textEditModalConfirmHandle() {
			let group = this.canvas.getActiveObject()
			let originObj = group.originObj
			originObj.text = this.textEditModelContent
			this._updateTextGroup(group)
		},

		_updateTextGroup(group) {
			let obj = group.originObj
			let text = this._getObjFromGroup(group, 'text')
			let bgObj = this._getObjFromGroup(group, 'bg')
			if(text && bgObj) {
				text.set({
					text: obj.text,
					fontSize: obj.css.text.fontSize,
					underline: obj.css.text.underline,
					linethrough: obj.css.text.linethrough,
					fontStyle: obj.css.text.fontStyle,
					fontWeight: obj.css.text.fontWeight,
					fontFamily: obj.css.text.fontFamily,
					fill: obj.css.text.color,
					charSpacing: obj.css.text.charSpacing,
					dirty: true
				})
				let {
					css: {
						border: {
							width: {
								x: borderWidthX = 0,
								y: borderWidthY = 0,
							}, 
							color: borderColor = '', 
							radius: {
								x: radiusX = 0,
								y: radiusY = 0
							}
						},
						text: {
							color = '#333',
							fontSize = 18,
						},
						top = 0,
						left = 0,
					},
					originX = 'center',
					originY = 'center',
				} = obj
				bgObj.set({
					width: text.width + borderWidthX * 2,
					height: text.height + borderWidthY * 2,
					fill: borderColor,
					rx: radiusX, 
					ry: radiusY,
					originX: 'center',
					originY: 'center',
					dirty: true
				})
				this.canvas.requestRenderAll()
				this.canvas._updateActiveSelection()
			}
			this.updateCanvasState()
		},

		_getObjFromGroup(group, type) {
			let ctype = group ? group.ctype : ''
			if(ctype == 'text-group' || ctype == 'image-group') {
				let objs = group['_objects'].filter(item=>item.ctype == type)
				return objs ? objs[0] : null
			}
			return null
		},



		initWxConfig: function() {
			let ua = navigator.userAgent.toLowerCase();
			this.weAppEnv= ua.match(/MicroMessenger/i)=="micromessenger"
			let url =  window.location.href, 
				appId = 'wx60ced1c8de7908d5'
			url = url.split('#')[0]  
			console.log('/api-pass/wechat/h5/sign/share' + '?app_id=' + appId + '&url=' + url)
			const promise = axios.get('/api-pass/wechat/h5/sign/share' + '?app_id=' + appId + '&url=' + url);
			promise.then(res => {
				if (res.data.code == 200) {
					const sign = res.data.data;
					this._wxConfigJSSDK(sign);
				}
			});
			promise.catch((err) => {
				console.log(err.response);
			})
		},
		_wxConfigJSSDK: function(sign){
			console.log(sign)
			console.log(parseInt(new Date().getTime() / 1000), sign.timestamp, new Date().getTime() / 1000 - sign)
			wx.config({
				debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
				appId: sign.appId + '', // 必填，公众号的唯一标识
				timestamp: parseInt(sign.timestamp), // 必填，生成签名的时间戳
				nonceStr: sign.nonceStr + '', // 必填，生成签名的随机串
				signature: sign.signature + '',// 必填，签名
				jsApiList: ['chooseImage', 'downloadImage', 'previewImage', 'uploadImage'] // 必填，需要使用的JS接口列表
			})
			wx.ready(function(){
                wx.checkJsApi({
                    jsApiList: [
                        'chooseImage',
                        'previewImage',
                        'uploadImage',
                        'downloadImage'
                    ],
                    success: function (res) {
                        if (res.checkResult.getLocation == false) {
                            console.error('你的微信版本太低，不支持微信JS接口，请升级到最新的微信版本！');
                            return;
                        }else{
							console.log('授权成功', res)
                        }
					},
					fail: err=> {
						console.log(err)
					}
                });
			});

			wx.error(function(res){
				console.error(res)
			});
		},


		showEditPopover: function(eventData, target) {
			let group = this.canvas.getActiveObject()
			let textObj = this._getObjFromGroup(group, 'text')
			this.showTextEditModel = true
			this.textEditModelContent = textObj.text
		},

		colorMenuHandle: function() {
			this.showColorMenu = true
		},

		fontSizeIncease: function() {
			let group = this.canvas.getActiveObject()
			let {originObj} = group
			originObj.css.text.fontSize +=2
			if(originObj.css.text.fontSize > 40) {
				originObj.css.text.fontSize = 40
			}
			this._updateTextGroup(group)
		},

		fontSzieDecease: function() {
			let group = this.canvas.getActiveObject()
			let {originObj} = group
			originObj.css.text.fontSize -=2
			if(originObj.css.text.fontSize < 10) {
				originObj.css.text.fontSize = 10
			}
			this._updateTextGroup(group)
		},

		blodHandle: function() {
			let group = this.canvas.getActiveObject()
			let {originObj} = group,
				fontWeight = originObj.css.text.fontWeight
			originObj.css.text.fontWeight = fontWeight == 600 ? 200 : 600
			this._updateTextGroup(group)
		},

		italicHandle: function() {
			let group = this.canvas.getActiveObject()
			let {originObj} = group,
				fontStyle = originObj.css.text.fontStyle
			originObj.css.text.fontStyle = fontStyle == 'italic' ? 'normal' : 'italic';
			this._updateTextGroup(group)			
		},
		througnLineHandle: function() {
			let group = this.canvas.getActiveObject()
			let {originObj} = group,
				linethrough = originObj.css.text.linethrough
			originObj.css.text.linethrough = !linethrough
			this._updateTextGroup(group)
		},
		underLineHandle: function() {
			let group = this.canvas.getActiveObject()
			let {originObj} = group,
				underline = originObj.css.text.underline
			originObj.css.text.underline = !underline
			this._updateTextGroup(group)
		},

		colorPickerHandle: function(color) {
			let group = this.canvas.getActiveObject()
			let {originObj} = group
			originObj.css.text.color = color
			this._updateTextGroup(group)
		},

		fontSelectHandle: function(font) {
			let group = this.canvas.getActiveObject()
			let {originObj} = group
			originObj.css.text.fontFamily = font.name
			this._updateTextGroup(group)
			this.fontList.forEach(item=> {
				item.chosen = item.name == font.name
			})
			this.fontList = Array.from(this.fontList) //修复选择多次后点击√失效的BUG
			console.log(this.fontList)
		},

		fontSheetHandle() {
			let group = this.canvas.getActiveObject()
			let {originObj} = group
			this.loadFonts(originObj.css.text.fontFamily)
			this.showFontChooseSheet = true
		},

		_logPoster: function() {
			LOGGER.createPoster(this.$route, this.id)
		},

		_getLocalImage: function() {
			let _this = this
			return new Promise((resolve, reject)=> {
				wx.chooseImage({
					count: 1, // 默认9
					sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
					sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
					success: function (res) {
						var localIds = res.localIds; // 返回选定照片的本地ID列表，localId可以作为img标签的src属性显示图片
						let targetImgId = localIds[0]
						console.log('wkwebview is' +  !!window.__wxjs_is_wkwebview)
						wx.getLocalImgData({
							localId: targetImgId,
							success: (res) => {
								let localData = res.localData // localData是图片的base64数据，可以用img标签显示
								if (localData.indexOf('data:image') != 0) {
									//判断是否有这样的头部 => 兼容安卓机型（安卓机不包含data头）
									localData = 'data:image/jpeg;base64,' +  localData
								}
								resolve(localData)
							},
							fail: err=> {
								reject(err)
							}
						})
					},
					fail: err=> {
						reject(err)
					}
				});
			})
		},


		//old

		setupJSON: async function(json) {
			await this.initBgImg()
			let _this = this
			for(let i = 0; i < json.length; i++) {
				let obj = json[i],
					zIndex = i+2
				if (obj.type == 'text') {
					await _this.textHandler(obj, zIndex*2)
				}
				if (obj.type == 'image') {
					await _this.imageHandler(obj, zIndex-1)
				}
				if (obj.type == 'qrcode') {
					await _this.qrcodeHandler(obj, zIndex)
				}
				if (obj.type == 'block') {
					await _this.blockHandler(obj, zIndex)
				}
				if (obj.type == 'line') {
					await _this.lineHandler(obj, zIndex)
				}
				if (obj.type == 'circle') {
					await _this.circleHandler(obj, zIndex)
				}
			}
		},
		textHandler: function(obj,zIndex){
			let _this = this
			return new Promise((resolve, reject)=> {
				let text = new fabric.Text(
					obj.text,
					{ 
						left: obj.css.left * SCALE_RATIO + MARGIN_LEFT, 
						top: obj.css.top * SCALE_RATIO + MARGIN_TOP,
						fontFamily: obj.css.fontFamily || 'PingFang SC', //'Comic Sans' ,
						fontSize: (obj.css.fontSize || 20) * SCALE_RATIO,
						fontWeight: obj.css.fontWeight,
						fill:obj.css.color,
						originX: obj.css.originX ||'left',
						backgroundColor:obj.css.backgroundColor,
						width: obj.css.width * SCALE_RATIO,
						breakWords: obj.css.breakWords,
						textAlign: obj.css.textAlign ||'left',
						padding: 10,
						stroke: obj.css.stroke|| '',
						shadow: obj.css.shadow|| '',
					})
				text.on('selected', _this.selectedHandle)
				text.set('selectable', !!obj.editAble);
				_this.canvas.add(text);
				text.moveTo(zIndex)
				text.bringForward();
				resolve()
			})
		},
		imageHandler: function(obj,zIndex) {
			let _this = this
			return new Promise((resolve, reject)=> {
				new fabric.Image.fromURL(obj.url, function(img) {
					let scaleRatio = obj.css.width  * SCALE_RATIO / img.width 
					img.scale(scaleRatio); 
					img.on('selected', _this.selectedHandle)
					img.top = obj.css.top  * SCALE_RATIO + MARGIN_TOP
					img.left = obj.css.left * SCALE_RATIO + MARGIN_LEFT
					img.originX = obj.css.originX || 'left'
					//不设置height,避免选择框不能匹配图像边缘
					img.on('selected', _this.selectedHandle)
					img.set('selectable', !!obj.editAble);
					img.set('dirty', true);
					if(obj.css.borderRadius) {						
						var clipPath = new fabric.Circle({
							radius: img.width / 2,
							originX: 'center',
							originY: 'center',
						});
						img.clipPath = clipPath
					}
					img.set('originObj', obj)
					_this.canvas.add(img);
					img.moveTo(zIndex)
					if(obj.id == 'bg-img') {
						_this.bgObj = img
						img.set('selectable', true);
					}
				}, {crossOrigin: 'anonymous'});
				resolve()
			})
		},
		qrcodeHandler: function(obj,zIndex){
			let _this = this
			return new Promise(async (resolve, reject)=> {
				let qrcode = await QRCode.toDataURL(obj.content, {color: {dark: obj.css.color, light: obj.css.light},margin:3})
				new fabric.Image.fromURL(qrcode, function(img) {
					let scaleRatio = obj.css.width * SCALE_RATIO / img.width 
					img.scale(scaleRatio);
					img.top = obj.css.top * SCALE_RATIO + MARGIN_TOP
					img.left = obj.css.left * SCALE_RATIO + MARGIN_LEFT
					img.width = img.width
					img.height = obj.css.height / scaleRatio
					img.on('selected', _this.selectedHandle)
					img.set('selectable', !!obj.editAble);
					_this.canvas.add(img);
					img.moveTo(zIndex)
				}, {crossOrigin: 'anonymous'});
				resolve()
			})
		},

		done: function() {
			this.showImgMenu = false
			this.showTextMenu = false
			this.canvas.discardActiveObject().requestRenderAll()
			this.selectedHandle(null)
		},

		_cripImageFromCanvas(base64Image) {
			return new Promise((resolve, reject)=> {
				let tmpCanvas = document.createElement('canvas');
				tmpCanvas.width = POSTER_WIDTH - 1
				tmpCanvas.height = POSTER_HEIGHT - 1
				let tmpctx = tmpCanvas.getContext('2d');
				tmpCanvas.id = 'tmp-canvas'
				document.body.appendChild(tmpCanvas)
				let tmpFabricCanvas = new fabric.Canvas('tmp-canvas')
				let tmpImg = document.createElement('img')
				tmpImg.src = base64Image
				tmpImg.onload = ()=> {
					var imgInstance = new fabric.Image(tmpImg, {
						left: - MARGIN_LEFT - 1,
						top: - MARGIN_TOP,
						// width: POSTER_WIDTH,
						// height: POSTER_HEIGHT
					});
					tmpFabricCanvas.add(imgInstance);
					let res = tmpFabricCanvas.toDataURL({
							format: 'jpeg',
							quality: 1.0,
							multiplier: 1
						})
					let tmpEl = document.getElementById('tmp-canvas')
					document.body.removeChild(tmpEl.parentNode)
					resolve(res)
				}
			})
		},

    

		save:  async function() {

			this._logPoster()

			this.canvas.discardActiveObject()
			let thumbImage = this.canvas.toDataURL(
				{
					format: 'jpeg', //png会在模拟器无法显示，真机未知
					quality: 1,
					multiplier: 1.5,
					left: MARGIN_LEFT + 0.5,
					top: MARGIN_TOP + 0.5,
					width: POSTER_WIDTH - 1,
					height: POSTER_HEIGHT - 1,
				}),
				cripedImage = thumbImage
			if (this.weAppEnv) {
				this.saveHandleForWeApp(cripedImage)
				return
			}
			let obj = {
				type: 'generate-img',
				img: cripedImage
			}
			window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify(obj))
		},

		saveHandleForWeApp: function(thumbImage) {
			let imgKey = this.posterKey
			console.log('imgKey = ', imgKey)
			wx.miniProgram.postMessage({
				data: {
					baseImg: thumbImage , 
					imgKey: imgKey,
				}
			})
			let params = '?imgKey=' + imgKey + '&route=' + JSON.stringify(this.$route.params)
			// wx.miniProgram.navigateBack({delta: 1})
			let path = '/shop/painter/preview' + params
			wx.miniProgram.redirectTo({
				url: path,
			})
		},

		initCanvasState() {
			this.canvas.on('object:modified', this.updateCanvasState);
			this.canvas.on('object:added', this.updateCanvasState);
			let config = this.config, _canvasObject = this.canvas
			config.canvasState = [this._getCanvasJsonData()];
			config.currentStateIndex = 0
			config.disableUndo = true
			config.disableRedo = true
			config.enable = true
		},

		_getCanvasJsonData() {
			var jsonData = this.canvas.toJSON(['ctype', 'originObj']);
			return JSON.stringify(jsonData);
		},
		_loadDataFromJson(json) {
			let _this = this
			return new Promise((resolve, reject)=> {
				_this.canvas.loadFromJSON(json, ()=> {
					resolve()
				})
			})
		},

		//2021-4-13 撤销&重做
		updateCanvasState: function () {
			//要处理的是区分点击的是undo还是redo
			//如果既不是undo也不是redo则说明是用户在此基础上有了新的操作
			console.log('update')
			if(!this.config.enable) return;
			if(this.config.undoStatus) {
				console.log('object modify after undo')
			} else if (this.config.redoStatus) {
				console.log('object modify after redo')
			} else {
				this.config.currentStateIndex += 1;
				this.config.canvasState.splice(this.config.currentStateIndex, this.config.canvasState.length, this._getCanvasJsonData())
				this.config.disableUndo = false
				this.config.disableRedo = true
			}
			console.log(this.config.currentStateIndex, this.config.undoStatus, this.config.redoStatus, this.config.canvasState)
		},
		undo: async function () {
			let config = this.config
			console.log('undo')
			config.undoStatus = true
			config.disableRedo = false
			this.loading = true
			config.currentStateIndex > 0 && await this._loadDataFromJson(config.canvasState[config.currentStateIndex - 1])
			this.canvas.requestRenderAll()
			config.currentStateIndex -= 1;
			config.currentStateIndex == 0 && (config.disableUndo = true)
			console.log(config)
			config.undoStatus = false
			this.loading = false
		},

		redo: async function () {
			let config = this.config
			console.log('redo')
			config.redoStatus = true
			this.loading = true
			config.currentStateIndex < config.canvasState.length - 1 && await this._loadDataFromJson(config.canvasState[config.currentStateIndex + 1])
			this.canvas.requestRenderAll()
			config.currentStateIndex += 1;
			config.disableUndo = false
			config.currentStateIndex == config.canvasState.length - 1  && (config.disableRedo = true)
			console.log(config)
			config.redoStatus = false
			this.loading = false
		},








	}
}
